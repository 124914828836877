import * as urls from '../apiUrls';
import { b64toBlob } from 'utils/util';
import { doPaymentRequest, doRequest } from 'utils/request';

const searchCustomers = (params, highlight, skip, take) => {
  return doRequest(
    `${urls.SEARCH_CUSTOMERS}`,
    'get',
    {
      highlight: highlight,
      query: params,
      skip: skip,
      take: take,
    },
    '',
    true
  );
};

const postValidateFinancialCustomer = (params) => {
  return doRequest(`${urls.VALIDATE_FINANCIAL_CUSTOMER}`, 'post', params);
};

const getCustomer = (id) => {
  return doRequest(`${urls.CUSTOMERS}/${id}`, 'get');
};

const getPreferredLangauge = () => {
  return doRequest(`${urls.GET_PREFERRED_LANGAUGE}`, 'get');
};

const postCustomer = (params) => {
  return doRequest(`${urls.CUSTOMERS}`, 'post', params);
};

const putCustomer = (id, params) => {
  return doRequest(`${urls.CUSTOMERS}/${id}`, 'put', params);
};

const getAddress = (postcode) => {
  return doRequest(`${urls.GET_ADDRESS}/${postcode}`, 'get');
};

const getCountries = () => {
  return doRequest(`${urls.GET_COUNTRIES}`, 'get');
};

const uploadCustomerImage = (params, id) => {
  let blob = b64toBlob(params);
  let formData = new FormData();
  formData.append('file', blob);

  return doRequest(
    `${urls.UPLOAD_CUSTOMER_IMAGE}/${id}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

const uploadNomineeImage = (params, customerId, nomineeId) => {
  let blob = b64toBlob(params);
  let formData = new FormData();
  formData.append('file', blob);

  return doRequest(
    `${urls.UPLOAD_NOMINEE_IMAGE}/${customerId}/${nomineeId}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

const performOCRDrivingLicence = (params) => {
  const data = params.substr(params.indexOf(',') + 1);
  return doRequest(
    `${urls.OCR_DRIVING_LICENCE}`,
    'post',
    { payload: data },
    'application/json'
  );
};

const validateCustomer = (params) => {
  return doRequest(
    `${urls.CUSTOMER_VALIDATE}`,
    'post',
    params,
    'application/json'
  );
};

const performOCRPassport = (params) => {
  const data = params.substr(params.indexOf(',') + 1);
  return doRequest(
    `${urls.OCR_PASSPORT}`,
    'post',
    { payload: data },
    'application/json'
  );
};

const verfiyMobileNumberSMS = (params) => {
  return doRequest(
    `${urls.VERIFY_MOBILE_NUMBER_SMS}`,
    'post',
    params,
    'application/json'
  );
};

const verfiyMobileNumberVerifyCode = (params) => {
  return doRequest(
    `${urls.VERIFY_MOBILE_NUMBER_VERIFY_CODE}`,
    'post',
    params,
    'application/json'
  );
};

const uploadCustomerDoc = (params, customerId) => {
  const { image, documentTypeId, expiryDate } = params;
  const blob = b64toBlob(image);

  let formData = new FormData();
  formData.append('file', blob);
  formData.append('documentTypeId', documentTypeId);
  if (expiryDate) formData.append('expiryDate', expiryDate);

  return doRequest(
    `${urls.CUSTOMER_DOCUMENT}/${customerId}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

const deleteDoc = (customerId, documentId) => {
  return doRequest(
    `${urls.CUSTOMER_DOCUMENT}/${customerId}/${documentId}`,
    'delete'
  );
};

const getImage = (imageUrl) => {
  return doRequest(
    `${process.env.REACT_APP_API_DOMAIN}${imageUrl}`,
    'get',
    '',
    '',
    '',
    'blob'
  );
};

const getDocTypes = () => {
  return doRequest(`${urls.GET_CUSTOMER_DOCTYPES}`, 'get');
};

const getTpaDocTypes = () => {
  return doRequest(`${urls.GET_TPA_DOCTYPES}`, 'get');
};

const getNoteTypes = () => {
  return doRequest(`${urls.NOTE_TYPES}`, 'get');
};

const postCustomerNote = (params) => {
  return doRequest(`${urls.ADD_NOTE}`, 'post', params);
};
const postCustomerStopListNote = (params) => {
  return doRequest(`${urls.ADD_STOP_LIST_NOTE}`, 'post', params);
};

const getNotesByCustomer = (customerID) => {
  return doRequest(`${urls.REVIEW_NOTES_BY_CUSTOMER}/${customerID}`, 'get');
};

const getCustomerVulnerableStatus = (customerID) => {
  return doRequest(`${urls.CUSTOMER_VULNERABLE_STATUS}/${customerID}`, 'get');
};

const getCustomerLastVisits = (customerId) => {
  return doRequest(`${urls.CUSTOMER_LAST_VISITS}/${customerId}`, 'get');
};

const createLiteCustomer = (params) => {
  return doRequest(`${urls.CREATE_LITE_CUSTOMER}`, 'post', params);
};

const updateTMCustomerImage = (customerId) => {
  return doRequest(`${urls.TM_DEFAULT_IMAGE}/${customerId}`, 'post');
};

const validatePayeeAccount = (requestPayload) => {
  return doPaymentRequest(
    `${urls.VALIDATE_PAYEE_ACCOUNT}`,
    'post',
    requestPayload
  );
};

const postPaymentsNotificationsList = (requestPayload) => {
  return doPaymentRequest(
    `${urls.FASTER_PAYMENT_LIST}`,
    'post',
    requestPayload
  );
};

const updateCustomerMarketingPreference = (requestPayload) => {
  return doRequest(
    `${urls.CUSTOMERS}/${requestPayload?.customerId}/marketing-preferences`,
    'post',
    requestPayload
  );
};

export {
  getNotesByCustomer,
  postCustomerNote,
  getNoteTypes,
  searchCustomers,
  getCustomer,
  getPreferredLangauge,
  getAddress,
  postCustomer,
  putCustomer,
  getCountries,
  uploadCustomerImage,
  getImage,
  uploadCustomerDoc,
  getDocTypes,
  getTpaDocTypes,
  deleteDoc,
  getCustomerVulnerableStatus,
  performOCRDrivingLicence,
  performOCRPassport,
  validateCustomer,
  verfiyMobileNumberSMS,
  verfiyMobileNumberVerifyCode,
  getCustomerLastVisits,
  createLiteCustomer,
  updateTMCustomerImage,
  postCustomerStopListNote,
  validatePayeeAccount,
  postPaymentsNotificationsList,
  uploadNomineeImage,
  postValidateFinancialCustomer,
  updateCustomerMarketingPreference,
};
