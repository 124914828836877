export const VALUATION_HEADERS = {
  VALUATION_REF: 'Valuation Reference:',
  ITEM_DESC: 'Item Description:',
  ITEM_DETAILS: 'Item Details',
  ITEM_IMAGES: 'Item Images',
  SUBMITTED_BY: 'Submitted by',
  CUSTOMER_DETAILS: 'Customer Details',
  CHECKLIST: 'Store Checklist',
  ITEM_HISTORY: 'Item History',
  VAL_REQUEST: 'Valuation Request',
  NOTES: 'Notes',
  STONES: 'Stone Details',
  COINS: 'Coin Details',
  NOTIFICATION_HEADING: 'Notifications',
};

export const LABELS = {
  DESIRED_AMOUNT: 'Desired Amount',
  PREVIOUS_OFFER: 'Previous Offer',
  VALUATION: 'Valuation',
  NOTES: 'Valuation Notes',
  SEARCH_BY: 'Search by',
  FILTER_BY: 'Filter by',
};

export const BUTTON_LABELS = {
  BACK: 'Back to Listing',
  REJECT: 'Reject',
  EDIT: 'Requires Edit',
  OFFER: 'Offer',
  VIEW: 'View',
  IN_USE: 'In Use',
  SEARCH: 'Search',
  RESET: 'Reset',
};

export const WORKQUEUE_TABS = [
  { key: 0, title: 'Live', requestId: 1 },
  { key: 1, title: 'Closed', requestId: 2 },
];

export const VALUATION_VIEW_TYPE = {
  VIEW_ITEM: 1,
  VIEW_LISTING: 2,
  VIEW_ITEM_IN_PLEDGE: 3,
};

export const VALUATION_ACTIONS = {
  NEW_REQUEST: 1,
  OFFERED: 2,
  EDIT_REQUESTED: 3,
  EDITED: 4,
  RESUBMITTED: 5,
  ACCEPTED: 6,
  REJECTED: 7,
  EXPIRED: 8,
  DECLINED: 9,
};
export const VALUATION_STATUS = {
  OFFERED: 'Offered',
};
export const VALUATION_SERVICE = {
  PLEDGE: 'Pledge',
  PURCHASE: 'Purchase',
};

export const VALUATION_ERROR = {
  NOTES: { VALUE: 1, ERROR: 'Please an add valuation note.' },
  AMOUNT: { VALUE: 2, ERROR: 'Please an add valuation amount.' },
};

export const ERROR_MESSAGE = {
  NO_DETAILS: 'No details found for selected Valuation',
};

export const VALUATION_LABELS = {
  IMAGE: 'Image',
  DESCRIPTION: 'Item Description',
  WEIGHT: 'Weight',
  DESIRED_AMOUNT: 'Desired Amount',
  VALUATION_AMOUNT: 'Valuation Amount',
  CONDITIONAL_OFFER: 'Conditional Offer',
  STATUS: 'Status',
  SERVICE_TYPE: 'Service Type: ',
  TITLE: ' Valuation',
  ONLINE: 'Online Valuation',
  DECLINE: 'Decline',
  REJECT: 'Confirm Rejection',
  RESUBMIT: 'Resubmit',
  EDIT: 'Edit',
  CREATE: 'Create Pledge/Purchase',
  CUSTOMER_NAME: 'Customer',
  CUSTOMER_MOBILE: 'Mobile No.',
  CUSTOMER_EMAIL: 'Email ID.',
  NOTE_PLACEHOLDER: 'Add a note',
  OFFERED_AMOUNT_KEY: 'offeredAmount',
  DECLINE_TITLE: 'Valuation Request Declined',
  SELECT: 'Select',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  OTHER: 'Other',
  REASON: 'Reason',
  MESSAGE: 'Message',
};

export const VALUATION_STATUS_ID = {
  NEW_REQUEST: 6,
  EDIT_REQUESTED: 7,
  OFFERED: 9,
  REJECTED: 10,
  RESUBMITTED: 16,
  DECLINED: 11,
  REJECT_ACCEPTED: 14,
  EDITED: 17,
  OFFER_IN_PROGRESS: 15,
};
