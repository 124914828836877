import React, { useCallback } from 'react';
import { Modal, Row, Col } from 'antd';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
  }
`;

const StyledHeader = styled(Row)`
  font-size: var(--font-size-20);
  white-space: initial;
  color: var(--red);
  font-weight: var(--font-weight-500);
  line-height: 25px;
  text-align: center;
`;

const StyledDescription = styled(Row)`
  color: var(--text-color);
  font-style: normal;
  font-weight: normal;
  margin: 10px 45px 0 45px;
  font-size: var(--font-size-16);
  text-align: center;
  white-space: break-spaces;

  &.message-bold {
    font-weight: var(--font-weight-600);
  }
`;
const StyledContentDescription = styled(Row)`
  font-weight: var(--font-weight-500);
  line-height: 25px;
  justify-content: center;
  font-style: normal;
  font-size: var(--font-size-20);
  text-align: center;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Alert = ({
  id,
  width = 457,
  zIndex = 1000,
  visible,
  message,
  secondaryMessage = '',
  secondaryMsgClassName = '',
  contentMessageClassName = '',
  status,
  closeIcon = false,
  onClick = () => {},
  onCancel = () => {},
  yesButtonText = 'Yes',
  yesButtonDisabled = false,
  isSuccessBtnOnRight = false,
  isCancelBtn = true,
  itemProp = 'secondary',
  itemPropCancel = 'secondary',
  alertMessage = 'Alert',
  alertMessageStyle = '',
  contentMessage,
  cancelButtonText = 'No',
  isLoading = false,
  cancelButtonDisabled = false,
  isLoadingIcon = false,
  isFooterButtonVisible = true,
  maskClosable = true,
  yesButtonStyle = { marginRight: '20px' },
  // JustifyContent to be passed as space-between or center accordingly
  justifyContent = 'center',
}: any) => {
  const onOkClick = () => {
    onClick(false);
  };

  const onCancelClick = useCallback(
    (event: any) => {
      onCancel(event);
    },
    [onCancel]
  );

  return (
    <StyledModal
      zIndex={zIndex}
      centered
      open={visible}
      closable={status === 403 ? true : closeIcon ? true : false}
      key={id}
      width={width}
      onOk={onOkClick}
      onCancel={onCancelClick}
      className="alert-modal-wrapper"
      maskClosable={maskClosable}
      footer={
        isFooterButtonVisible === false ||
        status === 403 ? null : isSuccessBtnOnRight ? (
          <Row justify={justifyContent}>
            <Button
              itemProp={itemPropCancel}
              key="back"
              onClick={onCancelClick}
            >
              {cancelButtonText}
            </Button>
            <Button
              itemProp={itemProp}
              key="submit"
              onClick={onOkClick}
              disabled={yesButtonDisabled}
              style={{ marginRight: '20px' }}
              loading={isLoading}
            >
              {yesButtonText}
            </Button>
          </Row>
        ) : (
          <Row justify={justifyContent}>
            <Button
              itemProp={itemProp}
              key="submit"
              onClick={onOkClick}
              disabled={yesButtonDisabled}
              loading={isLoading}
              style={yesButtonStyle}
            >
              {yesButtonText}
            </Button>
            {isCancelBtn && (
              <Button
                itemProp={itemPropCancel}
                key="back"
                onClick={onCancelClick}
                disabled={cancelButtonDisabled}
              >
                {cancelButtonText}
              </Button>
            )}
          </Row>
        )
      }
    >
      <Row>
        <Col span={24}>
          <StyledHeader
            justify="center"
            align="middle"
            style={alertMessageStyle}
          >
            {alertMessage}
          </StyledHeader>
          <StyledContentDescription className={contentMessageClassName}>
            {contentMessage}
          </StyledContentDescription>
          {isLoadingIcon && (
            <Row justify="center">
              <Col>
                <Spin indicator={antIcon} />
              </Col>
            </Row>
          )}
          <StyledDescription justify="center" align="middle">
            {message}
          </StyledDescription>
          {secondaryMessage && (
            <StyledDescription
              justify="center"
              align="middle"
              className={secondaryMsgClassName}
            >
              {secondaryMessage}
            </StyledDescription>
          )}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default Alert;
